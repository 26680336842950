// import {
//   setToken,
//   //  getToken, deleteToken
// } from "../../utils/token";

import { postSendMail } from '../webpages/service'

// Action Types
export const actionTypes = {
  AUTH_CHECK: "AUTH_CHECK",
  AUTH_LOGIN: "AUTH_LOGIN",
  AUTH_LOGOUT: "AUTH_LOGOUT",
  AUTH_REFRESH_TOKEN: "AUTH_REFRESH_TOKEN",
  AUTH_RESET_PASSWORD: "AUTH_RESET_PASSWORD",
  AUTH_USER: "AUTH_USER",

  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED: "LOGIN_FAILED",

  GET_BANNER_REQUEST: "GET_BANNER_REQUEST",
  GET_BANNER_SUCCESS: "GET_BANNER_SUCCESS",
  GET_BANNER_FAILED: "GET_BANNER_FAILED",

  GET_MEMBERBANNER_REQUEST: "GET_MEMBERBANNER_REQUEST",
  GET_MEMBERBANNER_SUCCESS: "GET_MEMBERBANNER_SUCCESS",
  GET_MEMBERBANNER_FAILED: "GET_MEMBERBANNER_FAILED",

  GET_NONMEMBERBANNER_REQUEST: "GET_NONMEMBERBANNER_REQUEST",
  GET_NONMEMBERBANNER_SUCCESS: "GET_NONMEMBERBANNER_SUCCESS",
  GET_NONMEMBERBANNER_FAILED: "GET_NONMEMBERBANNER_FAILED",

  GET_EMPLOYEEBANNER_REQUEST: "GET_EMPLOYEEBANNER_REQUEST",
  GET_EMPLOYEEBANNER_SUCCESS: "GET_EMPLOYEEBANNER_SUCCESS",
  GET_EMPLOYEEBANNER_FAILED: "GET_EMPLOYEEBANNER_FAILED",

  GET_SERVICES_REQUEST: "GET_SERVICES_REQUEST",
  GET_SERVICES_SUCCESS: "GET_SERVICES_SUCCESS",
  GET_SERVICES_FAILED: "GET_SERVICES_FAILED",

  GET_HEALTHCAREPACKAGES_REQUEST: "GET_HEALTHCAREPACKAGES_REQUEST",
  GET_HEALTHCAREPACKAGES_SUCCESS: "GET_HEALTHCAREPACKAGES_SUCCESS",
  GET_HEALTHCAREPACKAGES_FAILED: "GET_HEALTHCAREPACKAGES_FAILED",

  GET_HEALTHCAREPLANS_REQUEST: "GET_HEALTHCAREPLANS_REQUEST",
  GET_HEALTHCAREPLANS_SUCCESS: "GET_HEALTHCAREPLANS_SUCCESS",
  GET_HEALTHCAREPLANS_FAILED: "GET_HEALTHCAREPLANS_FAILED",

  GET_AFFILIATEWEBSITES_REQUEST: "GET_AFFILIATEWEBSITES_REQUEST",
  GET_AFFILIATEWEBSITES_SUCCESS: "GET_AFFILIATEWEBSITES_SUCCESS",
  GET_AFFILIATEWEBSITES_FAILED: "GET_AFFILIATEWEBSITES_FAILED",

  GET_ARTICLES_REQUEST: "GET_ARTICLES_REQUEST",
  GET_ARTICLES_SUCCESS: "GET_ARTICLES_SUCCESS",
  GET_ARTICLES_FAILED: "GET_ARTICLES_FAILED",

  GET_CLINICS_REQUEST: "GET_CLINICS_REQUEST",
  GET_CLINICS_SUCCESS: "GET_CLINICS_SUCCESS",
  GET_CLINICS_FAILED: "GET_CLINICS_FAILED",

  GET_VIDEOS_REQUEST: "GET_VIDEOS_REQUEST",
  GET_VIDEOS_SUCCESS: "GET_VIDEOS_SUCCESS",
  GET_VIDEOS_FAILED: "GET_VIDEOS_FAILED",

  GET_PERKS_REQUEST: "GET_PERKS_REQUEST",
  GET_PERKS_SUCCESS: "GET_PERKS_SUCCESS",
  GET_PERKS_FAILED: "GET_PERKS_FAILED",

  GET_CAREERS_REQUEST: "GET_CAREERS_REQUEST",
  GET_CAREERS_SUCCESS: "GET_CAREERS_SUCCESS",
  GET_CAREERS_FAILED: "GET_CAREERS_FAILED",

  GET_FLASHES_REQUEST: "GET_FLASHES_REQUEST",
  GET_FLASHES_SUCCESS: "GET_FLASHES_SUCCESS",
  GET_FLASHES_FAILED: "GET_FLASHES_FAILED",

  GET_TESTIMONIALS_REQUEST: "GET_TESTIMONIALS_REQUEST",
  GET_TESTIMONIALS_SUCCESS: "GET_TESTIMONIALS_SUCCESS",
  GET_TESTIMONIALS_FAILED: "GET_TESTIMONIALS_FAILED",

  GET_CONTACT_REQUEST: "GET_CONTACT_REQUEST",
  GET_CONTACT_SUCCESS: "GET_CONTACT_SUCCESS",
  GET_CONTACT_FAILED: "GET_CONTACT_FAILED",

  GET_FAQS_REQUEST: "GET_FAQS_REQUEST",
  GET_FAQS_SUCCESS: "GET_FAQS_SUCCESS",
  GET_FAQS_FAILED: "GET_FAQS_FAILED",

  GET_METATAGS_REQUEST: "GET_METATAGS_REQUEST",
  GET_METATAGS_SUCCESS: "GET_METATAGS_SUCCESS",
  GET_METATAGS_FAILED: "GET_METATAGS_FAILED",

  POST_SUBSCRIPTION_REQUEST: "POST_SUBSCRIPTION_REQUEST",
  POST_SUBSCRIPTION_SUCCESS: "POST_SUBSCRIPTION_SUCCESS",
  POST_SUBSCRIPTION_FAILED: "POST_SUBSCRIPTION_FAILED",

  POST_CONTACTMAIL_REQUEST: "POST_CONTACTMAIL_REQUEST",
  POST_CONTACTMAIL_SUCCESS: "POST_CONTACTMAIL_SUCCESS",
  POST_CONTACTMAIL_FAILED: "POST_CONTACTMAIL_FAILED",

  POST_EMPLOYEEPLANMAIL_REQUEST: "POST_EMPLOYEEPLANMAIL_REQUEST",
  POST_EMPLOYEEPLANMAIL_SUCCESS: "POST_EMPLOYEEPLANMAIL_SUCCESS",
  POST_EMPLOYEEPLANMAIL_FAILED: "POST_EMPLOYEEPLANMAIL_FAILED",

  POST_WEBCHAT_EMPLOYEEPLANMAIL_REQUEST: "POST_WEBCHAT_EMPLOYEEPLANMAIL_REQUEST",
  POST_WEBCHAT_EMPLOYEEPLANMAIL_SUCCESS: "POST_WEBCHAT_EMPLOYEEPLANMAIL_SUCCESS",
  POST_WEBCHAT_EMPLOYEEPLANMAIL_FAILED: "POST_WEBCHAT_EMPLOYEEPLANMAIL_FAILED",

  POST_CAREERSMAIL_REQUEST: "POST_CAREERSMAIL_REQUEST",
  POST_CAREERSMAIL_SUCCESS: "POST_CAREERSMAIL_SUCCESS",
  POST_CAREERSMAIL_FAILED: "POST_CAREERSMAIL_FAILED",

  POST_SENDMAIL_REQUEST: "POST_SENDMAIL_REQUEST",
  POST_SENDMAIL_SUCCESS: "POST_SENDMAIL_SUCCESS",
  POST_SENDMAIL_FAILED: "POST_SENDMAIL_FAILED",
};

// Action creators
export const actionCreators = {
  login: (payload) => ({ type: actionTypes.LOGIN_REQUEST, payload }),

  // Get from API

  getBanner: () => ({ type: actionTypes.GET_BANNER_REQUEST }),
  getMemberBanner: () => ({ type: actionTypes.GET_MEMBERBANNER_REQUEST }),
  getNonMemberBanner: () => ({ type: actionTypes.GET_NONMEMBERBANNER_REQUEST }),
  getEmployeeBanner: () => ({ type: actionTypes.GET_EMPLOYEEBANNER_REQUEST }),
  getServices: () => ({ type: actionTypes.GET_SERVICES_REQUEST }),
  getHealthcarePackages: () => ({
    type: actionTypes.GET_HEALTHCAREPACKAGES_REQUEST,
  }),
  getHealthcarePlans: () => ({ type: actionTypes.GET_HEALTHCAREPLANS_REQUEST }),
  getAffiliateWebsites: () => ({
    type: actionTypes.GET_AFFILIATEWEBSITES_REQUEST,
  }),
  getArticles: () => ({ type: actionTypes.GET_ARTICLES_REQUEST }),
  getClinics: () => ({ type: actionTypes.GET_CLINICS_REQUEST }),
  getVideos: () => ({ type: actionTypes.GET_VIDEOS_REQUEST }),
  getPerks: () => ({ type: actionTypes.GET_PERKS_REQUEST }),
  getCareers: () => ({ type: actionTypes.GET_CAREERS_REQUEST }),
  getFlashes: () => ({ type: actionTypes.GET_FLASHES_REQUEST }),
  getTestimonials: () => ({ type: actionTypes.GET_TESTIMONIALS_REQUEST }),
  getContact: () => ({ type: actionTypes.GET_CONTACT_REQUEST }),
  getFaqs: () => ({ type: actionTypes.GET_FAQS_REQUEST }),
  getMetaTags: () => ({ type: actionTypes.GET_METATAGS_REQUEST }),

  // Post to API

  postSubscription: (params) => ({
    type: actionTypes.POST_SUBSCRIPTION_REQUEST,
    params,
  }),

  postContactMail: (params) => ({
    type: actionTypes.POST_CONTACTMAIL_REQUEST,
    params,
  }),

  postEmployeePlanMail: (params) => ({
    type: actionTypes.POST_EMPLOYEEPLANMAIL_REQUEST,
    params,
  }),

  postWebchatEmployeePlanMail: (params) => ({
    type: actionTypes.POST_WEBCHAT_EMPLOYEEPLANMAIL_REQUEST,
    params,
  }),

  postCareersMail: (params) => ({
    type: actionTypes.POST_CAREERSMAIL_REQUEST,
    params,
  }),

  postSendMail: (params) => ({
    type: actionTypes.POST_SENDMAIL_REQUEST,
    params,
  }),
};

// Reducer
const initialState = {
  isAuthenticated: false,
  bannerIsLoading: true,
  careersIsLoading: true,
  flashesIsLoading: true,
  perksIsLoading: true,
  videosIsLoading: true,
  affiliateWebsitesIsLoading: true,
  servicesIsLoading: true,
  newsArticlesIsLoading: true,
  flashArticlesIsLoading: true,
  clinicsIsLoading: true,
  healthcarePlansIsLoading: true,
  healthcarePackagesIsLoading: true,
  metaIsLoading: true,
  faqIsLoading: true,
  contactIsLoading: true,
  testimonialsIsLoading: true,
  sendIsLoading: true,
  sendMailIsLoading: true,
  accessToken: null,
  error: null,
  banner: [],
  memberBanner: [],
  nonMemberBanner: [],
  employeeBanner: [],
  services: [],
  healthcarePackages: [],
  healthcarePlans: [],
  affiliateWebsites: [],
  articles: [],
  clinics: [],
  videos: [],
  perks: [],
  careers: [],
  flashes: [],
  testimonials: [],
  subscription: [],
  contactMail: [],
  contact: [],
  faqs: [],
  employeePlanMail: [],
  careersMail: [],
  metatags: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    // GET FROM API

    case actionTypes.GET_BANNER_SUCCESS:
      return Object.assign({}, state, {
        bannerIsLoading: false,
        error: null,
        banner: action.payload,
      });

    case actionTypes.GET_BANNER_REQUEST:
      return Object.assign({}, state, {
        bannerIsLoading: true,
        error: null,
      });

    case actionTypes.GET_BANNER_FAILED:
      return Object.assign({}, state, {
        bannerIsLoading: false,
        error: null,
      });

    case actionTypes.GET_MEMBERBANNER_SUCCESS:
      return Object.assign({}, state, {
        bannerIsLoading: false,
        error: null,
        memberBanner: action.payload,
      });

    case actionTypes.GET_MEMBERBANNER_REQUEST:
      return Object.assign({}, state, {
        bannerIsLoading: true,
        error: null,
      });

    case actionTypes.GET_MEMBERBANNER_FAILED:
      return Object.assign({}, state, {
        bannerIsLoading: false,
        error: null,
      });

    case actionTypes.GET_NONMEMBERBANNER_SUCCESS:
      return Object.assign({}, state, {
        bannerIsLoading: false,
        error: null,
        nonMemberBanner: action.payload,
      });

    case actionTypes.GET_NONMEMBERBANNER_REQUEST:
      return Object.assign({}, state, {
        bannerIsLoading: true,
        error: null,
      });

    case actionTypes.GET_NONMEMBERBANNER_FAILED:
      return Object.assign({}, state, {
        bannerIsLoading: false,
        error: null,
      });

    case actionTypes.GET_EMPLOYEEBANNER_SUCCESS:
      return Object.assign({}, state, {
        bannerIsLoading: false,
        error: null,
        employeeBanner: action.payload,
      });

    case actionTypes.GET_EMPLOYEEBANNER_REQUEST:
      return Object.assign({}, state, {
        bannerIsLoading: true,
        error: null,
      });

    case actionTypes.GET_EMPLOYEEBANNER_FAILED:
      return Object.assign({}, state, {
        bannerIsLoading: false,
        error: null,
      });

    case actionTypes.GET_SERVICES_SUCCESS:
      return Object.assign({}, state, {
        servicesIsLoading: false,
        error: null,
        services: action.payload,
      });

    case actionTypes.GET_SERVICES_REQUEST:
      return Object.assign({}, state, {
        servicesIsLoading: true,
        error: null,
      });

    case actionTypes.GET_SERVICES_FAILED:
      return Object.assign({}, state, {
        servicesIsLoading: false,
        error: null,
      });

    case actionTypes.GET_HEALTHCAREPACKAGES_SUCCESS:
      return Object.assign({}, state, {
        healthcarePackagesIsLoading: false,
        error: null,
        healthcarePackages: action.payload,
      });

    case actionTypes.GET_HEALTHCAREPACKAGES_REQUEST:
      return Object.assign({}, state, {
        healthcarePackagesIsLoading: true,
        error: null,
      });

    case actionTypes.GET_HEALTHCAREPACKAGES_FAILED:
      return Object.assign({}, state, {
        healthcarePackagesIsLoading: false,
        error: null,
      });

    case actionTypes.GET_HEALTHCAREPLANS_SUCCESS:
      return Object.assign({}, state, {
        healthcarePlansIsLoading: false,
        error: null,
        healthcarePlans: action.payload,
      });

    case actionTypes.GET_HEALTHCAREPLANS_REQUEST:
      return Object.assign({}, state, {
        healthcarePlansIsLoading: true,
        error: null,
      });

    case actionTypes.GET_HEALTHCAREPLANS_FAILED:
      return Object.assign({}, state, {
        healthcarePlansIsLoading: false,
        error: null,
      });

    case actionTypes.GET_AFFILIATEWEBSITES_SUCCESS:
      return Object.assign({}, state, {
        affiliateWebsitesIsLoading: false,
        error: null,
        affiliateWebsites: action.payload,
      });

    case actionTypes.GET_AFFILIATEWEBSITES_REQUEST:
      return Object.assign({}, state, {
        affiliateWebsitesIsLoading: true,
        error: null,
      });

    case actionTypes.GET_AFFILIATEWEBSITES_FAILED:
      return Object.assign({}, state, {
        affiliateWebsitesIsLoading: false,
        error: null,
      });

    case actionTypes.GET_ARTICLES_REQUEST:
      return Object.assign({}, state, {
        newsArticlesIsLoading: true,
        error: null,
      });

    case actionTypes.GET_ARTICLES_SUCCESS:
      return Object.assign({}, state, {
        newsArticlesIsLoading: false,
        error: null,
        articles: action.payload,
      });

    case actionTypes.GET_ARTICLES_FAILED:
      return Object.assign({}, state, {
        newsArticlesIsLoading: false,
        error: null,
      });

    case actionTypes.GET_CLINICS_SUCCESS:
      return Object.assign({}, state, {
        clinicsIsLoading: false,
        error: null,
        clinics: action.payload,
      });

    case actionTypes.GET_CLINICS_REQUEST:
      return Object.assign({}, state, {
        clinicsIsLoading: true,
        error: null,
      });

    case actionTypes.GET_CLINICS_FAILED:
      return Object.assign({}, state, {
        clinicsIsLoading: false,
        error: null,
      });

    case actionTypes.GET_VIDEOS_REQUEST:
      return Object.assign({}, state, {
        videosIsLoading: true,
        error: null,
      });

    case actionTypes.GET_VIDEOS_SUCCESS:
      return Object.assign({}, state, {
        videosIsLoading: false,
        error: null,
        videos: action.payload,
      });

    case actionTypes.GET_VIDEOS_FAILED:
      return Object.assign({}, state, {
        videosIsLoading: false,
        error: null,
      });

    case actionTypes.GET_PERKS_REQUEST:
      return Object.assign({}, state, {
        perksIsLoading: true,
        error: null,
      });

    case actionTypes.GET_PERKS_SUCCESS:
      return Object.assign({}, state, {
        perksIsLoading: false,
        error: null,
        perks: action.payload,
      });

    case actionTypes.GET_PERKS_FAILED:
      return Object.assign({}, state, {
        perksIsLoading: false,
        error: null,
      });

    case actionTypes.GET_CAREERS_REQUEST:
      return Object.assign({}, state, {
        careersIsLoading: true,
        error: null,
      });

    case actionTypes.GET_CAREERS_SUCCESS:
      return Object.assign({}, state, {
        careersIsLoading: false,
        error: null,
        careers: action.payload,
      });

    case actionTypes.GET_CAREERS_FAILED:
      return Object.assign({}, state, {
        careersIsLoading: false,
        error: null,
      });

    case actionTypes.GET_FLASHES_REQUEST:
      return Object.assign({}, state, {
        flashArticlesIsLoading: true,
        error: null,
      });

    case actionTypes.GET_FLASHES_SUCCESS:
      return Object.assign({}, state, {
        flashArticlesIsLoading: false,
        error: null,
        flashes: action.payload,
      });

    case actionTypes.GET_FLASHES_FAILED:
      return Object.assign({}, state, {
        flashArticlesIsLoading: false,
        error: null,
      });

    case actionTypes.GET_TESTIMONIALS_REQUEST:
      return Object.assign({}, state, {
        testimonialsIsLoading: true,
        error: null,
      });

    case actionTypes.GET_TESTIMONIALS_SUCCESS:
      return Object.assign({}, state, {
        testimonialsIsLoading: false,
        error: null,
        testimonials: action.payload,
      });

    case actionTypes.GET_TESTIMONIALS_FAILED:
      return Object.assign({}, state, {
        testimonialsIsLoading: false,
        error: null,
      });

    case actionTypes.GET_CONTACT_SUCCESS:
      return Object.assign({}, state, {
        contactIsLoading: false,
        error: null,
        contact: action.payload,
      });

    case actionTypes.GET_CONTACT_REQUEST:
      return Object.assign({}, state, {
        contactIsLoading: true,
        error: null,
      });

    case actionTypes.GET_CONTACT_FAILED:
      return Object.assign({}, state, {
        contactIsLoading: false,
        error: null,
      });

    case actionTypes.GET_FAQS_SUCCESS:
      return Object.assign({}, state, {
        faqIsLoading: false,
        error: null,
        faqs: action.payload,
      });

    case actionTypes.GET_FAQS_REQUEST:
      return Object.assign({}, state, {
        faqIsLoading: true,
        error: null,
      });

    case actionTypes.GET_FAQS_FAILED:
      return Object.assign({}, state, {
        faqIsLoading: false,
        error: null,
      });

    case actionTypes.GET_METATAGS_SUCCESS:
      return Object.assign({}, state, {
        metaIsLoading: false,
        error: null,
        metaTags: action.payload,
      });

    case actionTypes.GET_METATAGS_REQUEST:
      return Object.assign({}, state, {
        metaIsLoading: true,
        error: null,
      });

    case actionTypes.GET_METATAGS_FAILED:
      return Object.assign({}, state, {
        metaIsLoading: false,
        error: null,
      });

    // POST TO API

    case actionTypes.POST_SUBSCRIPTION_SUCCESS:
      return Object.assign({}, state, {
        sendIsLoading: false,
        error: null,
      });

    case actionTypes.POST_SUBSCRIPTION_REQUEST:
      return Object.assign({}, state, {
        sendIsLoading: true,
        error: null,
      });

    case actionTypes.POST_SUBSCRIPTION_FAILED:
      return Object.assign({}, state, {
        sendIsLoading: false,
        error: null,
      });

    case actionTypes.POST_CONTACTMAIL_SUCCESS:
      if (action.payload) {
        postSendMail(action.payload.inquiry_job_email);
        postSendMail(action.payload.inquiry_medicard_job_mail);
      }
      return Object.assign({}, state, {
        sendIsLoading: false,
        error: null,
      });

    case actionTypes.POST_CONTACTMAIL_REQUEST:
      return Object.assign({}, state, {
        sendIsLoading: true,
        error: null,
      });

    case actionTypes.POST_CONTACTMAIL_FAILED:
      return Object.assign({}, state, {
        sendIsLoading: false,
        error: null,
      });

    case actionTypes.POST_EMPLOYEEPLANMAIL_SUCCESS:
      if (action.payload) {
        postSendMail(action.payload.inquiry_employee_plan_job_mail_admin);
        postSendMail(action.payload.inquiry_employee_plan_job_mail);
      }
      return Object.assign({}, state, {
        sendIsLoading: false,
        error: null,
      });

    case actionTypes.POST_EMPLOYEEPLANMAIL_REQUEST:
      return Object.assign({}, state, {
        sendIsLoading: true,
        error: null,
      });

    case actionTypes.POST_EMPLOYEEPLANMAIL_FAILED:
      return Object.assign({}, state, {
        sendIsLoading: false,
        error: null,
      });

    case actionTypes.POST_WEBCHAT_EMPLOYEEPLANMAIL_SUCCESS:
      if (action.payload) {
        postSendMail(action.payload.webchat_company_info_email);
      }
      return Object.assign({}, state, {
        sendIsLoading: false,
        error: null,
      });

    case actionTypes.POST_WEBCHAT_EMPLOYEEPLANMAIL_REQUEST:
      return Object.assign({}, state, {
        sendIsLoading: true,
        error: null,
      });

    case actionTypes.POST_WEBCHAT_EMPLOYEEPLANMAIL_FAILED:
      return Object.assign({}, state, {
        sendIsLoading: false,
        error: null,
      });

    case actionTypes.POST_CAREERSMAIL_SUCCESS:
      if (action.payload) {
        postSendMail(action.payload.inquiry_career_job_mail);
        postSendMail(action.payload.inquiry_career_medicard_job_mail);
      }
      return Object.assign({}, state, {
        sendIsLoading: false,
        error: null,
      });

    case actionTypes.POST_CAREERSMAIL_REQUEST:
      return Object.assign({}, state, {
        sendIsLoading: true,
        error: null,
      });

    case actionTypes.POST_CAREERSMAIL_FAILED:
      return Object.assign({}, state, {
        sendIsLoading: false,
        error: null,
      });

    case actionTypes.POST_SENDMAIL_SUCCESS:
      return Object.assign({}, state, {
        sendMailIsLoading: false,
        error: null,
      });

    case actionTypes.POST_SENDMAIL_REQUEST:
      return Object.assign({}, state, {
        sendMailIsLoading: true,
        error: null,
      });

    case actionTypes.POST_SENDMAIL_FAILED:
      return Object.assign({}, state, {
        sendMailIsLoading: false,
        error: null,
      });

    default:
      return state;
  }
};
