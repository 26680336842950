import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// import { NavLink } from "react-router-dom";
// import LoadingComponent from "./commons/loader";
import { purgeStoredState } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { deleteToken } from "./utils/token";
import { actionCreators as brandsActionCreators } from "./modules/webpages/reducer";
import { notification, Row, Col, Button } from "antd";
import "./App.css";

import { Layout } from "antd";

import Loadable from "react-loadable";

import LoadingComponent from "./commons/loader";

import ReactGA from "react-ga";
// import ReactPixel from "react-facebook-pixel";

const MainHeader = Loadable({
  loader: () => import("./modules/webpages/pages/components/header"),
  loading: LoadingComponent,
});

const MainFooter = Loadable({
  loader: () => import("./modules/webpages/pages/components/footer"),
  loading: LoadingComponent,
});

const Webchat = Loadable({
  loader: () => import("./modules/webpages/pages/components/webchat"),
  loading: LoadingComponent,
});

const { Content } = Layout;

class App extends Component {
  state = { logoutVisible: false, cookieVisible: false };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  showModal = () => {
    this.setState({
      logoutVisible: true,
    });
  };

  onOk = () => {
    const persistConfig = {
      key: "root",
      storage,
    };

    const authPersistConfig = {
      key: "auth",
      storage,
    };

    deleteToken();
    purgeStoredState(persistConfig);
    purgeStoredState(authPersistConfig);
    window.location.href = "/";
  };

  hideModal = () => {
    this.setState({
      logoutVisible: false,
    });
  };

  handleCloseCookie = () => {
    sessionStorage.setItem("cookieShow", true);
    notification.close(1);
  };

  componentDidMount() {
    let activeURL = this.props.children.props.location.pathname.split("/")[1];

    window.scrollTo({ top: 0, left: 0 });

    if (activeURL === "") {
      this.props.getBanner();
    }

    if (
      activeURL === "member" ||
      activeURL === "non-member" ||
      activeURL === "advanced-search"
    ) {
      this.props.getCareers();
      this.props.getFlashes();
      this.props.getPerks();
      this.props.getVideos();
      this.props.getAffiliateWebsites();
    }

    if (activeURL === "member" || activeURL === "advanced-search") {
      this.props.getMemberBanner();
      this.props.getServices();
    }

    if (activeURL === "non-member") {
      this.props.getNonMemberBanner();
    }

    if (
      activeURL === "online-store" ||
      activeURL === "non-member" ||
      activeURL === "advanced-search"
    ) {
      this.props.getHealthcarePlans();
      this.props.getHealthcarePackages();
      this.props.getEmployeeBanner();
    }

    if (
      activeURL === "news-promos-announcements" ||
      activeURL === "advanced-search"
    ) {
      this.props.getArticles();
    }

    if (
      activeURL === "offices-and-clinics" ||
      activeURL === "advanced-search"
    ) {
      this.props.getClinics();
    }

    if (activeURL === "faq" || activeURL === "advanced-search") {
      this.props.getFaqs();
    }

    if (activeURL === "contact-us") {
      this.props.getContact();
    }

    if (activeURL === "about-us") {
      this.props.getTestimonials();
    }

    this.props.getMetaTags();

    // Google Analytics
    ReactGA.initialize(`${process.env.REACT_APP_GA}`);
    ReactGA.pageview(window.location.pathname);

    // FB Pixels
    // ReactPixel.init(`${process.env.REACT_APP_PIXEL}`);

    // Cookie   deleted 09/25/2023/jbsario
    // if (sessionStorage.getItem("cookieShow") === null) {
    //   notification.open({
    //     key: 1,
    //     message: `This website uses cookies`,
    //     duration: 0,
    //     onClose: this.handleCloseCookie,
    //     description: (
    //       <Row>
    //         <Col>
    //           We use cookies to make our website work better and improve your
    //           online experience. By continuing to browse this website you hereby
    //           accept the use of cookies. For more info, see our{" "}
    //           <a href="/privacy-notice">Privacy Notice</a> and{" "}
    //           <a href="/eula">End-User License Agreement</a>.
    //         </Col>
    //       </Row>
    //     ),
    //     btn: (
    //       <Button
    //         type="ghost"
    //         onClick={(value) => this.handleCloseCookie(value)}
    //       >
    //         I Agree
    //       </Button>
    //     ),
    //     placement: "bottomRight",
    //   });
    // }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.children.props.location !== this.props.children.props.location
    ) {
      ReactGA.initialize(`${process.env.REACT_APP_GA}`);
      ReactGA.pageview(window.location.pathname);
    }
  }

  render() {
    const {
      bannerIsLoading,
      careersIsLoading,
      flashesIsLoading,
      perksIsLoading,
      videosIsLoading,
      affiliateWebsitesIsLoading,
      servicesIsLoading,
      newsArticlesIsLoading,
      flashArticlesIsLoading,
      clinicsIsLoading,
      healthcarePlansIsLoading,
      healthcarePackagesIsLoading,
      metaIsLoading,
      faqIsLoading,
      contactIsLoading,
      testimonialsIsLoading,
    } = this.props;

    const contentLoad =
      bannerIsLoading ||
      careersIsLoading ||
      flashesIsLoading ||
      perksIsLoading ||
      videosIsLoading ||
      affiliateWebsitesIsLoading ||
      servicesIsLoading ||
      newsArticlesIsLoading ||
      flashArticlesIsLoading ||
      clinicsIsLoading ||
      healthcarePlansIsLoading ||
      healthcarePackagesIsLoading ||
      metaIsLoading ||
      faqIsLoading ||
      contactIsLoading ||
      testimonialsIsLoading;

    if (contentLoad) {
      return <LoadingComponent isLoading={contentLoad} />;
    }

    let dynamicURL = this.props.children.props.match.params.prevRoute;
    let activeURL = this.props.children.props.location.pathname.split("/")[1];
    let history = this.props.children.props.history;

    return (
      <React.Fragment>
        {/* <MainHeader
          activeURL={activeURL}
          dynamicURL={dynamicURL}
          history={history}
        /> */}
        <Webchat />
        {/* <Layout className="app">
          <Content className="fade-in-up">{this.props.children}</Content>
          <MainFooter />
        </Layout> */}
      </React.Fragment>
    );
  }
}

// const mapDispatchToProps = dispatch => ({});

const mapStateToProps = (state, ownState) => ({
  metaIsLoading: state.webpage.metaIsLoading,
  isLoading: state.webpage.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(brandsActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
