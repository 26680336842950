import { getProviderToken } from "./token";
import urlFormat from "./urlFormat";
import { codeMessage } from "./errors";

const PROVIDER_URL = `${process.env.REACT_APP_PROVIDER_API}`;

const fetchProviderOpts = async (method, body = null) => {
    const accessToken = await getProviderToken();

    let option = null;

    if (body instanceof FormData) {
        option = getFormDataOption(method, body, accessToken);
    } else {
        option = getJsonOption(method, body, accessToken);
    }

    return option;
};

const getJsonOption = (method, body, accessToken) => {
    let option = Object.assign(
        {},
        {
            method,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        },
        body
            ? {
                body: JSON.stringify(body),
            }
            : {}
    );

    if (accessToken) {
        option = Object.assign(
            {},
            {
                method,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            },
            body
                ? {
                    body: JSON.stringify(body),
                }
                : {}
        );
    }

    return option;
};

const getFormDataOption = (method, body, accessToken) => {
    let option = Object.assign(
        {},
        {
            method,
            headers: {
                Accept: "application/json",
            },
        },
        body
            ? {
                body: body,
            }
            : {}
    );

    if (accessToken) {
        option = Object.assign(
            {},
            {
                method,
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            },
            body
                ? {
                    body: body,
                }
                : {}
        );
    }

    return option;
};

export const PROVIDER_API = {
    get: async (url) => fetch(`${PROVIDER_URL}${url}`, await fetchProviderOpts("GET")),
    post: async (url, data) => fetch(`${PROVIDER_URL}${url}`, await fetchProviderOpts("POST", data)),
    put: async (url, data) => fetch(`${PROVIDER_URL}${url}`, await fetchProviderOpts("PUT", data)),
    patch: async (url, data) => fetch(`${PROVIDER_URL}${url}`, await fetchProviderOpts("PATCH", data)),
    delete: async (url) => fetch(`${PROVIDER_URL}${url}`, await fetchProviderOpts("DELETE")),
};

const request = async (url, options = {}) => {
    // Get the request method
    let method = "get";
    if (options.method) {
        method = options.method.toLowerCase();
    }

    // Get params
    if (options.params) {
        url = urlFormat(url, options.params);
    }

    let body = null;
    if (options.body) {
        body = options.body;
    }

    // Do the request
    try {
        const response = await PROVIDER_API[method](url, body);
        const json = await response.json();

        if (!response.ok) {
            throw codeMessage[json.error_code];
        }

        return json;
    } catch (err) {
        throw err;
    }
};

export { request };
