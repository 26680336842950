import Loadable from "react-loadable";
import LoadingComponent from "../../commons/loader";

export default [
  {
    path: "/",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/home"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/member",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/member"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/:prevRoute/view/:title?",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/member-view"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/non-member",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/non-member"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/:prevRoute/resource/:title?",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/resource-view"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/:prevRoute/videos/:id?",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/videos"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/:prevRoute/perks/:category?",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/perks"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/:prevRoute/careers",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/careers"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/:prevRoute/careers/view/:id?",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/career-view"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/:prevRoute/flash",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/flash"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/:prevRoute/flash/article/:id?",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/flash-view"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/news-promos-announcements",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/article"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/news-promos-announcements/article/:id?",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/article-view"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/online-store/:id?",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/online-store"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/online-store/healthcare-plan/:plan?",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/online-store-view"),
      loading: LoadingComponent,
    }),
  },
  {
    path:
      "/accredited-providers/:accredited?/:search?/:provinces?/:cities?/:specialization?/:hospitals?",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/accredited-providers"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/setup-appointment",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/setup-appointment"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/offices-and-clinics",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/offices-clinics"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/about-us",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/about-us"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/faq/:id?",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/faq"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/anti-money-policy",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/anti-money-policy"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/anti-corruption-policy",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/anti-corruption-policy"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/privacy-notice",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/privacy-policy"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/quality-policy",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/quality-policy"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/corporate-governance/:scope",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/corporate-governance"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/eula",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/eula"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/terms-and-conditions",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/terms-and-conditions"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/contact-us/:id?",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/contact-us"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/advanced-search/result/:search?",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/advanced-search"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/:prevRoute/under-construction",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/under-construction"),
      loading: LoadingComponent,
    }),
  },
];
