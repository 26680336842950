import { combineReducers } from "redux";
// import { persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";

import webpage from "../modules/webpages/reducer";
// const authPersistConfig = {
//   key: "auth",
//   storage
// };

export default combineReducers({
  webpage,
});
