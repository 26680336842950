import { all, call, put, takeEvery } from "redux-saga/effects";
import { actionTypes as authActionTypes } from "./reducer";
import { actionTypes as propertyActionTypes } from "./reducer";
import * as services from "./service";
import processAction from "../../utils/processAction";

function* login(action) {
  const { payload } = action;

  try {
    const auth = yield call(services.login, payload);

    yield put({ type: authActionTypes.LOGIN_SUCCESS, auth });
  } catch (err) {
    const error = err.message || err;
    yield put({ type: authActionTypes.LOGIN_FAILED, error });
  }
}

// GET FROM API

function* getBanner(action) {
  yield processAction({
    ...action,
    service: services.getBanner,
    success: propertyActionTypes.GET_BANNER_SUCCESS,
    failed: propertyActionTypes.GET_BANNER_FAILED,
  });
}

function* getMemberBanner(action) {
  yield processAction({
    ...action,
    service: services.getMemberBanner,
    success: propertyActionTypes.GET_MEMBERBANNER_SUCCESS,
    failed: propertyActionTypes.GET_MEMBERBANNER_FAILED,
  });
}

function* getNonMemberBanner(action) {
  yield processAction({
    ...action,
    service: services.getNonMemberBanner,
    success: propertyActionTypes.GET_NONMEMBERBANNER_SUCCESS,
    failed: propertyActionTypes.GET_NONMEMBERBANNER_FAILED,
  });
}

function* getEmployeeBanner(action) {
  yield processAction({
    ...action,
    service: services.getEmployeeBanner,
    success: propertyActionTypes.GET_EMPLOYEEBANNER_SUCCESS,
    failed: propertyActionTypes.GET_EMPLOYEEBANNER_FAILED,
  });
}

function* getServices(action) {
  yield processAction({
    ...action,
    service: services.getServices,
    success: propertyActionTypes.GET_SERVICES_SUCCESS,
    failed: propertyActionTypes.GET_SERVICES_FAILED,
  });
}

function* getHealthcarePackages(action) {
  yield processAction({
    ...action,
    service: services.getHealthcarePackages,
    success: propertyActionTypes.GET_HEALTHCAREPACKAGES_SUCCESS,
    failed: propertyActionTypes.GET_HEALTHCAREPACKAGES_FAILED,
  });
}

function* getHealthcarePlans(action) {
  yield processAction({
    ...action,
    service: services.getHealthcarePlans,
    success: propertyActionTypes.GET_HEALTHCAREPLANS_SUCCESS,
    failed: propertyActionTypes.GET_HEALTHCAREPLANS_FAILED,
  });
}

function* getAffiliateWebsites(action) {
  yield processAction({
    ...action,
    service: services.getAffiliateWebsites,
    success: propertyActionTypes.GET_AFFILIATEWEBSITES_SUCCESS,
    failed: propertyActionTypes.GET_AFFILIATEWEBSITES_FAILED,
  });
}

function* getArticles(action) {
  yield processAction({
    ...action,
    service: services.getArticles,
    success: propertyActionTypes.GET_ARTICLES_SUCCESS,
    failed: propertyActionTypes.GET_ARTICLES_FAILED,
  });
}

function* getClinics(action) {
  yield processAction({
    ...action,
    service: services.getClinics,
    success: propertyActionTypes.GET_CLINICS_SUCCESS,
    failed: propertyActionTypes.GET_CLINICS_FAILED,
  });
}

function* getVideos(action) {
  yield processAction({
    ...action,
    service: services.getVideos,
    success: propertyActionTypes.GET_VIDEOS_SUCCESS,
    failed: propertyActionTypes.GET_VIDEOS_FAILED,
  });
}

function* getPerks(action) {
  yield processAction({
    ...action,
    service: services.getPerks,
    success: propertyActionTypes.GET_PERKS_SUCCESS,
    failed: propertyActionTypes.GET_PERKS_FAILED,
  });
}

function* getCareers(action) {
  yield processAction({
    ...action,
    service: services.getCareers,
    success: propertyActionTypes.GET_CAREERS_SUCCESS,
    failed: propertyActionTypes.GET_CAREERS_FAILED,
  });
}

function* getFlashes(action) {
  yield processAction({
    ...action,
    service: services.getFlashes,
    success: propertyActionTypes.GET_FLASHES_SUCCESS,
    failed: propertyActionTypes.GET_FLASHES_FAILED,
  });
}

function* getTestimonials(action) {
  yield processAction({
    ...action,
    service: services.getTestimonials,
    success: propertyActionTypes.GET_TESTIMONIALS_SUCCESS,
    failed: propertyActionTypes.GET_TESTIMONIALS_FAILED,
  });
}

function* getContact(action) {
  yield processAction({
    ...action,
    service: services.getContact,
    success: propertyActionTypes.GET_CONTACT_SUCCESS,
    failed: propertyActionTypes.GET_CONTACT_FAILED,
  });
}

function* getFaqs(action) {
  yield processAction({
    ...action,
    service: services.getFaqs,
    success: propertyActionTypes.GET_FAQS_SUCCESS,
    failed: propertyActionTypes.GET_FAQS_FAILED,
  });
}

function* getMetaTags(action) {
  yield processAction({
    ...action,
    service: services.getMetaTags,
    success: propertyActionTypes.GET_METATAGS_SUCCESS,
    failed: propertyActionTypes.GET_METATAGS_FAILED,
  });
}

// POST TO API

function* postSubscription(action) {
  yield processAction({
    ...action,
    service: services.postSubscription,
    success: propertyActionTypes.POST_SUBSCRIPTION_SUCCESS,
    failed: propertyActionTypes.POST_SUBSCRIPTION_FAILED,
  });
}

function* postContactMail(action) {
  yield processAction({
    ...action,
    service: services.postContactMail,
    success: propertyActionTypes.POST_CONTACTMAIL_SUCCESS,
    failed: propertyActionTypes.POST_CONTACTMAIL_FAILED,
  });
}

function* postEmployeePlanMail(action) {
  yield processAction({
    ...action,
    service: services.postEmployeePlanMail,
    success: propertyActionTypes.POST_EMPLOYEEPLANMAIL_SUCCESS,
    failed: propertyActionTypes.POST_EMPLOYEEPLANMAIL_FAILED,
  });
}

function* postWebchatEmployeePlanMail(action) {
  yield processAction({
    ...action,
    service: services.postWebchatEmployeePlanMail,
    success: propertyActionTypes.POST_WEBCHAT_EMPLOYEEPLANMAIL_SUCCESS,
    failed: propertyActionTypes.POST_WEBCHAT_EMPLOYEEPLANMAIL_FAILED,
  });
}

function* postCareersMail(action) {
  yield processAction({
    ...action,
    service: services.postCareersMail,
    success: propertyActionTypes.POST_CAREERSMAIL_SUCCESS,
    failed: propertyActionTypes.POST_CAREERSMAIL_FAILED,
  });
}

function* postSendMail(action) {
  yield processAction({
    ...action,
    service: services.postSendMail,
    success: propertyActionTypes.POST_SENDMAIL_SUCCESS,
    failed: propertyActionTypes.POST_SENDMAIL_FAILED,
  });
}

function* watchLoginRequest() {
  yield all([
    yield takeEvery(authActionTypes.LOGIN_REQUEST, login),

    // GET FROM API
    yield takeEvery(propertyActionTypes.GET_BANNER_REQUEST, getBanner),

    yield takeEvery(
      propertyActionTypes.GET_MEMBERBANNER_REQUEST,
      getMemberBanner
    ),

    yield takeEvery(
      propertyActionTypes.GET_NONMEMBERBANNER_REQUEST,
      getNonMemberBanner
    ),

    yield takeEvery(
      propertyActionTypes.GET_EMPLOYEEBANNER_REQUEST,
      getEmployeeBanner
    ),

    yield takeEvery(propertyActionTypes.GET_SERVICES_REQUEST, getServices),

    yield takeEvery(
      propertyActionTypes.GET_HEALTHCAREPACKAGES_REQUEST,
      getHealthcarePackages
    ),

    yield takeEvery(
      propertyActionTypes.GET_HEALTHCAREPLANS_REQUEST,
      getHealthcarePlans
    ),

    yield takeEvery(
      propertyActionTypes.GET_AFFILIATEWEBSITES_REQUEST,
      getAffiliateWebsites
    ),

    yield takeEvery(propertyActionTypes.GET_ARTICLES_REQUEST, getArticles),

    yield takeEvery(propertyActionTypes.GET_CLINICS_REQUEST, getClinics),

    yield takeEvery(propertyActionTypes.GET_VIDEOS_REQUEST, getVideos),

    yield takeEvery(propertyActionTypes.GET_PERKS_REQUEST, getPerks),

    yield takeEvery(propertyActionTypes.GET_CAREERS_REQUEST, getCareers),

    yield takeEvery(propertyActionTypes.GET_FLASHES_REQUEST, getFlashes),

    yield takeEvery(
      propertyActionTypes.GET_TESTIMONIALS_REQUEST,
      getTestimonials
    ),

    yield takeEvery(propertyActionTypes.GET_CONTACT_REQUEST, getContact),

    yield takeEvery(propertyActionTypes.GET_FAQS_REQUEST, getFaqs),

    yield takeEvery(propertyActionTypes.GET_METATAGS_REQUEST, getMetaTags),

    // POST TO API
    yield takeEvery(
      propertyActionTypes.POST_SUBSCRIPTION_REQUEST,
      postSubscription
    ),

    yield takeEvery(
      propertyActionTypes.POST_CONTACTMAIL_REQUEST,
      postContactMail
    ),

    yield takeEvery(
      propertyActionTypes.POST_EMPLOYEEPLANMAIL_REQUEST,
      postEmployeePlanMail
    ),

    yield takeEvery(
      propertyActionTypes.POST_WEBCHAT_EMPLOYEEPLANMAIL_REQUEST,
      postWebchatEmployeePlanMail
    ),

    yield takeEvery(
      propertyActionTypes.POST_CAREERSMAIL_REQUEST,
      postCareersMail
    ),

    yield takeEvery(
      propertyActionTypes.POST_SENDMAIL_REQUEST,
      postSendMail
    ),
  ]);
}

export default [watchLoginRequest()];
