import React from "react";
import PropTypes from "prop-types";
import { Spin, Icon, Row, Col, Result } from "antd";

import "./index.css";

// set display name for component
const displayName = "CommonLoader";

// validate component properties
const propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.object,
};

const antIcon = <Icon type="loading" spin />;

const LoadingComponent = ({ isLoading, error }) => {
  // Handle the loading state
  if (isLoading) {
    return (
      <Row type="flex" justify="center" align="middle" className="error">
        <Col>
          <Row gutter={[0, 20]}>
            <Col style={{ textAlign: "center" }}>
              <Spin spinning={true} indicator={antIcon} />
            </Col>
            <Col className="title" style={{ textAlign: "center" }}>
              MediCard Philippines Inc.
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
  // Handle the error state
  else if (error) {
    return (
      <Row type="flex" justify="center" align="middle" className="error">
        <Result
          status="500"
          title="We Apologize"
          subTitle="Sorry, there was a problem loading the page."
        />
      </Row>
    );
  } else {
    return null;
  }
};

LoadingComponent.displayName = displayName;
LoadingComponent.propTypes = propTypes;

export default LoadingComponent;
