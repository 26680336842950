import API from "../../utils/api";
import request from "../../utils/api";
import { request as providerRequest } from '../../utils/providerApi';

export const login = async (credentials) => {
  try {
    credentials = {
      ...credentials,
      grant_type: "password",
      client_id: 2,
      client_secret: "3tajNZPebh9uqWxxmqnkuKVWIhh0Qr5mDC3iXSsQ",
    };

    const response = await API.post("oauth/token", credentials);

    const json = await response.json();

    if (!response.ok) {
      throw new Error(json.message);
    }

    return json;
  } catch (err) {
    throw err;
  }
};

// Get from API

export const getBanner = () => {
  return request("/main-banners");
};

export const getMemberBanner = () => {
  return request("/member-banners");
};

export const getNonMemberBanner = () => {
  return request("/non-member-banners");
};

export const getEmployeeBanner = () => {
  return request("/employee-banners");
};

export const getServices = () => {
  return request("/services");
};

export const getHealthcarePackages = () => {
  return request("/healthcare-packages");
};

export const getHealthcarePlans = () => {
  return request("/healthcare-plans");
};

export const getAffiliateWebsites = () => {
  return request("/affiliate-websites");
};

export const getArticles = () => {
  return request("/articles");
};

export const getClinics = () => {
  return request("/clinic-categories");
};

export const getVideos = () => {
  return request("/videos");
};

export const getPerks = () => {
  return request("/perks");
};

export const getCareers = () => {
  return request("/careers");
};

export const getFlashes = () => {
  return request("/flashes");
};

export const getTestimonials = () => {
  return request("/testimonials");
};

export const getContact = () => {
  return request("/contacts");
};

export const getFaqs = () => {
  return request("/faqs");
};

export const getMetaTags = () => {
  return request("/meta_tags");
};

// Post to API

export const postSubscription = (payload) => {
  return request("/newsletters", {
    method: "post",
    body: payload,
  });
};

export const postContactMail = (payload) => {
  return request("/contacts-mail", {
    method: "post",
    body: payload,
  });
};

export const postEmployeePlanMail = (payload) => {
  return request("/employee-plan-mail", {
    method: "post",
    body: payload,
  });
};

export const postWebchatEmployeePlanMail = (payload) => {
  return request("/webchat-employee-plan-mail", {
    method: "post",
    body: payload,
  });
};

export const postCareersMail = (payload) => {
  return request("/careers-mail", {
    method: "post",
    body: payload,
  });
};

// Send Email
export const postSendMail = (payload) => {
  return providerRequest("/prd-notif/email", {
    method: "post",
    body: payload,
  });
};

export const whoami = async () => {
  try {
    const response = await API.get("api/v1/whoami");

    const json = await response.json();

    if (!response.ok) {
      throw new Error(json.message);
    }

    return json;
  } catch (err) {
    throw err;
  }
};
