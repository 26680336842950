import { purgeStoredState } from "redux-persist";
import base64 from 'base-64'

const PROVIDER_API = `${process.env.REACT_APP_PROVIDER_API}`;
const BASIC_USERNAME = `${process.env.REACT_APP_PROVIDER_BASIC_USERNAME}`;
const BASIC_PASSWORD = `${process.env.REACT_APP_PROVIDER_BASIC_PASSWORD}`;
const PROVIDER_GRANT_TYPE = `${process.env.REACT_APP_PROVIDER_GRANT_TYPE}`;
const PROVIDER_USERNAME = `${process.env.REACT_APP_PROVIDER_USERNAME}`;
const PROVIDER_PASSWORD = `${process.env.REACT_APP_PROVIDER_PASSWORD}`;

export const setToken = accessToken => {
  window.localStorage.setItem("rlbpToken", accessToken);

  return accessToken;
};

export const getToken = () => {
  return window.localStorage.getItem("rlbpToken");
};

export const getProviderToken = () => {

  const formData = new FormData();
  formData.append('grant_type', PROVIDER_GRANT_TYPE);
  formData.append('username', PROVIDER_USERNAME);
  formData.append('password', PROVIDER_PASSWORD);

  return fetch(`${PROVIDER_API}/prd-auth/oauth/token`, {
    method: 'POST', // or 'PUT'
    headers: {
      Authorization: `Basic ${base64.encode(BASIC_USERNAME + ':' + BASIC_PASSWORD)}`,
    },
    body: formData,
  })
    .then(response => response.json())
    .then(data => {
      // console.log('Success:', data);
      return data.access_token
    })
    .catch((error) => {
      console.error('Error:', error);
    });
}

export const deleteToken = () => {
  return window.localStorage.removeItem("rlbpToken");
};

export const backToLoginPage = () => {
  deleteToken();
  purgeStoredState();
  window.location.href = "/";
};
