export { default as SearchOutline } from "@ant-design/icons/lib/outline/SearchOutline";

export { default as TwitterOutline } from "@ant-design/icons/lib/outline/TwitterOutline";

export { default as LoadingOutline } from "@ant-design/icons/lib/outline/LoadingOutline";

export { default as FilterOutline } from "@ant-design/icons/lib/outline/FilterOutline";

export { default as HomeOutline } from "@ant-design/icons/lib/outline/HomeOutline";

export { default as MedicineBoxOutline } from "@ant-design/icons/lib/outline/MedicineBoxOutline";

export { default as PrinterOutline } from "@ant-design/icons/lib/outline/PrinterOutline";

export { default as MobileOutline } from "@ant-design/icons/lib/outline/MobileOutline";

export { default as MenuOutline } from "@ant-design/icons/lib/outline/MenuOutline";

export { default as PhoneOutline } from "@ant-design/icons/lib/outline/PhoneOutline";

export { default as UserOutline } from "@ant-design/icons/lib/outline/UserOutline";

export { default as ClockCircleOutline } from "@ant-design/icons/lib/outline/ClockCircleOutline";

export { default as PlusOutline } from "@ant-design/icons/lib/outline/PlusOutline";

export { default as ShareAltOutline } from "@ant-design/icons/lib/outline/ShareAltOutline";

export { default as ControlOutline } from "@ant-design/icons/lib/outline/ControlOutline";

export { default as ArrowRightOutline } from "@ant-design/icons/lib/outline/ArrowRightOutline";

export { default as RightOutline } from "@ant-design/icons/lib/outline/RightOutline";

export { default as LeftOutline } from "@ant-design/icons/lib/outline/LeftOutline";

export { default as UpOutline } from "@ant-design/icons/lib/outline/UpOutline";

export { default as DownOutline } from "@ant-design/icons/lib/outline/DownOutline";

export { default as CaretRightOutline } from "@ant-design/icons/lib/outline/CaretRightOutline";

export { default as FacebookFilled } from "@ant-design/icons/lib/fill/FacebookFill";

export { default as InstagramFill } from "@ant-design/icons/lib/fill/InstagramFill";

export { default as CloseCircleFill } from "@ant-design/icons/lib/fill/CloseCircleFill";

export { default as MessageOutline } from "@ant-design/icons/lib/outline/MessageOutline";